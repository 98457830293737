<template>
  <TTView>
    <VRow>
      <VCol>
        <UserMatrixForm
          :entity="userMatrix"
          :companies="companies"
          @update:userId="userMatrix.userId = $event"
          @update:companyId="userMatrix.companyId = $event"
          @cancel="handleCancel"
          @submit="handleSubmit"
        />
      </VCol>
    </VRow>

    <VRow v-if="isExists">
      <VCol>
        <VCard>
          <VCardTitle>
            Матрица найдена
          </VCardTitle>

          <VCardText>
            <RouterLink
              :to="{
                name : Names.R_COMPETENCY_MATRIX_POSITIONS_USER_MATRIX_VIEW,
                params : { userId : userMatrix.userId, companyId : userMatrix.companyId }
              }"
            >
              Просмотр
            </RouterLink>
          </VCardText>
        </VCard>
      </VCol>
    </VRow>

    <VRow v-else-if="matrix.matrix === undefined">
      <VCol>
        <VCard>
          <VCardTitle>
            Матрица не найдена
          </VCardTitle>
        </VCard>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import { DEFAULT_POSITIONS_USER_MATRIX } from '../../components/competency-matrix/positions/common';
import UserMatrixForm from '../../components/competency-matrix/positions/UserMatrixForm.vue';

export default {
  name: 'PositionsUserMatrix',

  components: {
    UserMatrixForm,
  },

  inject: ['Names'],

  data() {
    return {
      userMatrix: { ...DEFAULT_POSITIONS_USER_MATRIX },
      matrix: { matrix: null },
      companies: [],
      loading: false,
    };
  },

  computed: {
    isExists() {
      return this.matrix.matrix !== null && this.matrix.matrix !== undefined;
    },
  },

  async created() {
    await this.fetch();
  },

  methods: {
    async fetch() {
      try {
        this.loading = true;

        const [companiesResponse] = await Promise.all([this.$di.api.Account.indexCompany()]);

        this.companies = companiesResponse.companies || [];
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    async handleSubmit() {
      try {
        this.loading = true;

        const { userMatrix: data } = this;
        const matrixResponse = await this.$di.api.CompetencyMatrix.PositionsUserMatrix(data);

        this.matrix = matrixResponse || {};
        this.$di.notify.snackSuccess('Матрица пользователя найдена');
      } catch (err) {
        this.$di.notify.errorHandler(err);
        this.matrix = { matrix: undefined };
      } finally {
        this.loading = false;
      }
    },

    handleCancel() {
      this.userMatrix = { ...DEFAULT_POSITIONS_USER_MATRIX };
      this.matrix = {};
    },
  },
};
</script>
